import React from 'react';

// Components
import PageTransition from '../components/PageTransition';
import DirectFeedbackUploadStep from '../views/DirectFeedbackUpload/DirectFeedbackUploadStep';
import LegalGuardianStep from '../views/LegalGuardian/LegalGuardianStep';
import { PageType } from '../views/Passengers/PassengerContainer';
import translations from '../translations/main.json';
import ThankYou from '../views/ClaimPage/ThankYou';
import PassengerStep from '../views/Passengers/PassengerStep';
import NotFoundPage from '../pages/404';
import SharablePageWrapper from '../views/SharablePageWrapper';

// IMPORTANT HINT:
// Including "passengers" on a claim backend call here is not possible for ShareablePassengerPage!
// Make sure to double check what you can include for each flow

const ShareablePassengerPage = () => {
  return (
    <PageTransition
      basepath={translations['cc4.seo.route.passenger']}
      showStepper={false}
      hideMenu={true}
    >
      <SharablePageWrapper path="/" pageType={PageType.Passenger} />
      <SharablePageWrapper
        path={':passengerId'}
        pageType={PageType.Passenger}
        redirect={false}
      >
        <PassengerStep path="/" pageType={PageType.Passenger} />
        <LegalGuardianStep
          pageType={PageType.Passenger}
          path={
            translations['cc4.seo.route.legal-guardian-info'] +
            '/:guardianIndex'
          }
        />
        <DirectFeedbackUploadStep
          pageType={PageType.Passenger}
          path={'/:documentTypeRouteParam'}
        />
        <ThankYou path={translations['cc4.seo.route.passenger-thank-you']} />
      </SharablePageWrapper>
      <NotFoundPage default />
    </PageTransition>
  );
};

export default ShareablePassengerPage;
