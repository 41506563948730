import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import Container from '../../../components/Container';
import Main from '../../../components/Main';
import Card from '../../../components/Card';
import StepTitle from '../../../components/StepTitle';
import Aside from '../../../components/Aside';
import Seo from '../../../components/seo';
import StepObserver from '../../../components/StepObserver';
import AsideText from '../../Passengers/PassengerForm/AsideText';

// Hooks
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';
import { useBuild } from '../../../services/GlobalStore/GlobalStore';

// Translations
import messages from './messages';
import { clearPart2Data } from '../../CompensationCheck';
import { steps } from '../../../utils/routes';

const ThankYou = ({ location }) => {
  const { formatMessage } = useIntl();
  const passengerObject = location?.state?.passenger;
  const [passenger] = useLocalStorageState(`MainBooker-${passengerObject?.id}`);

  const claimId = localStorage.getItem('yo:claimId');
  const claim = useBuild('claims', claimId);

  useEffect(() => {
    return clearPart2Data;
  }, []);

  return (
    <Container>
      <StepObserver stepName={steps.ThankYou} />
      <Seo title={messages.seoTitle} description={messages.seoDescription} />
      <Main>
        <Card>
          <StepTitle>
            {formatMessage(messages.shareablePassengerPageTitle, {
              name:
                passenger?.person.first_name ||
                passengerObject?.person.first_name,
            })}
          </StepTitle>
          <p className="mt-0 mx-10 sm:mx-0 text-black">
            {formatMessage(messages.shareablePassengerPageDescription, {
              br: <br />,
            })}
          </p>
        </Card>
      </Main>
      <Aside className="sm:py-20">
        <AsideText claim={claim} />
      </Aside>
    </Container>
  );
};

ThankYou.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ThankYou;
