import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.shareable-claim-thank-you-page.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.shareable-claim-thank-you-page.description',
    defaultMessage: '',
  },
  shareablePassengerPageTitle: {
    id: 'cc4.form.shareable-claim-thank-you-page.title',
    defaultMessage: `Thank you {name}!`,
  },
  shareablePassengerPageDescription: {
    id: 'cc4.form.shareable-claim-thank-you-page.description',
    defaultMessage:
      'Our team will now review the information and documents you have provided. {br} If we have everything we need, our team will start further work on your claim. If we are still missing something, we will get back to you.',
  },
});
